<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-combobox
      :value="plugin_event"
      @input="updatePluginEvent"
      :loading="loading"
      :items="items"
      item-text="name"
      :search-input.sync="search"
      hide-selected
      :plugin="plugin"
      :label="label"
      no-filter
      clearable
      return-object
    >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          <div>
            {{ data.item.name }}
          </div>
        </v-list-item-title>
        <v-list-item-subtitle>
          <div style="width: 200px" class="text-truncate">
            {{ data.item.slug }}
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{ item }">
      <v-chip small class="ma-2" color="primary" dark>
        {{ item.name }}
        <v-icon small @click="plugin = null">mdi-close</v-icon>
      </v-chip>
    </template>
    <template slot="append-item">
      <v-list-item v-if="more" @click="loadMore">
        <v-list-item-subtitle> Load More </v-list-item-subtitle>
      </v-list-item>
    </template>
  </v-combobox>
</template>
<script>
import { cloneDeep, debounce } from "lodash";
import SearchUtils from "@/search/utils";
import PluginApi from "@/plugin/api";

export default {
  name: "PluginEventCombobox",
  props: {
    modelValue: {
      type: Object,
      default: () => null,
    },
    label: {
      type: String,
      default: "Plugin Events",
    },
    plugin: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      more: false,
      numItems: 5,
      search: '',
      plugin_event: null,
    };
  },
  created() {
    this.plugin_event = cloneDeep(this.modelValue);
    this.fetchData();
  },
  methods: {
    updatePluginEvent(value) {
      this.plugin_event = value;
      this.$emit('input', value); // emit update to parent component
    },
    loadMore() {
      this.numItems += 5;
      this.fetchData();
    },
    fetchData() {
      if (!this.plugin) {
        return;
      }
      this.loading = true;

      let filterOptions = {
        q: this.search,
        sortBy: ["name"],
        descending: [false],
        itemsPerPage: this.numItems,
        filters: {
          plugin: [this.plugin.plugin],
        },
      };
      filterOptions = SearchUtils.createParametersFromTableOptions(filterOptions);
      PluginApi.getAllPluginEvents(filterOptions).then((response) => {
        this.items = response.data.items;
        this.more = this.items.length < response.data.total;
        this.loading = false;
      }).catch(() => this.loading = false);
    },
    getFilteredData: debounce(function () {
      this.fetchData();
    }, 500),
  },
  watch: {
    search(val) {
      val && val !== this.plugin_event && this.getFilteredData();
    },
    plugin(val) {
      this.plugin_event = null;
      this.$emit('input', null); // reset the modelValue
      val && this.getFilteredData();
    },
    modelValue(val) {
      this.plugin_event = cloneDeep(val);
    },
  },
}
</script>
