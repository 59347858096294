<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container>
      <cost-model-activity-dialog @input="addActivity($event)" />
    <v-row no-gutter align="center">
      <span class="text-subtitle-1"> Cost Model Activity </span>
      <v-spacer />

      <v-tooltip location="bottom">
        <template v-slot:activator="{ on }">
          <v-btn small icon @click="createActivityShow()" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Activity</span>
      </v-tooltip>
    </v-row>
    <span v-for="(activity, activity_idx) in activities" :key="activity_idx">
    <v-row align="center" dense>
      <v-col cols="12" sm="4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small icon @click="removeActivity(activity_idx)" v-on="on">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </template>
          <span>Remove Activity</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip class="short" size="small" dark :color="project.color" v-on="on">
              <v-icon small left>mdi-power-socket-uk</v-icon>
              {{ activity.plugin_event.plugin.title.substring(0, 20) }}
            </v-chip>
          </template>
          <span>{{ activity.plugin_event.plugin.title }}</span>
        </v-tooltip>
      </v-col>

      <v-col cols="12" sm="4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip class="short" size="small" dark :color="project.color" v-on="on">
              <v-icon small left>mdi-gesture-tap-button</v-icon>
              {{ activity.plugin_event.name.substring(0, 20) }}
            </v-chip>
          </template>
          <span>{{ activity.plugin_event.name }}</span>
        </v-tooltip>
      </v-col>

      <v-col cols="12" sm="4">
        <v-text-field
          label="Response Time (sec)"
          @input="itemChanged()"
          v-model="activity.response_time_seconds"
          type="text"
        />
      </v-col>
    </v-row>
  </span>
  </v-container>
</template>

  <script>
  import { mapFields } from "vuex-map-fields"
  import { mapActions } from "vuex"
  import { cloneDeep } from "lodash"
  import CostModelActivityDialog from "@/cost_model/CostModelActivityDialog.vue"

  export default {
    name: "CostModelActivityInput",

    components: {
      CostModelActivityDialog,
    },
    data() {
      return {}
    },
    props: {
      value: {
        type: Array,
        default: function () {
          return []
        },
      },
    },

    computed: {
      ...mapFields("cost_model", ["selected", "selected.project"]),
      activities: {
        get() {
          return cloneDeep(this.value)
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },

    methods: {
      ...mapActions("cost_model", ["createActivityShow"]),

      addActivity(activity) {
        for (let i = 0; i < this.activities.length; i++) {
          if (this.activities[i].plugin_event.id == activity.plugin_event.id) {
            this.$store.commit(
              "notification_backend/addBeNotification",
              {
                text: "Failed to add cost model activity. Please ensure all plugin events are unique for each cost model.",
              },
              { root: true }
            )
            return
          }
        }
        this.activities.push(cloneDeep(activity))
        this.$emit("input", this.activities)
      },
      removeActivity(activity_idx) {
        this.activities.splice(activity_idx, 1)
        this.$emit("input", this.activities)
      },
      itemChanged() {
        this.$emit("input", this.activities)
      },
    },
  }
  </script>
