<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row no-gutters>
      <v-col>
        <v-alert outlined closable icon="mdi-school" prominent type="info">
          Cost models allow users to create customized cost calculations for each IAO. This
          feature uses plugin-based tracking to provide a comprehensive view of participant
          activity, enabling a granular understanding of IAO costs. See
          <a href="https://netflix.github.io/dispatch/docs/administration/settings/cost_model" target="_blank">cost model documentation.</a>
        </v-alert>
      </v-col>
    </v-row>
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()"> New </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          v-model="q"
          append-inner-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        />
        <!-- Cards Grid -->
        <v-row class="mt-3">
          <v-col cols="12" md="6" v-for="(item, index) in items" :key="index">
            <v-card class="pa-2 mb-2">
              <v-card-title class="headline">
                <v-icon left>mdi-gesture-tap-button</v-icon>
                {{ item.name }}
              </v-card-title>
              <v-card-subtitle>
                {{ item.description }}
              </v-card-subtitle>
              <v-card-text>
                <v-checkbox :input-value="item.enabled" label="Enabled" disabled />
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="createEditShow(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="removeShow(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-data-table
            :items="items"
            :server-items-length="total || 0"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/cost_model/DeleteDialog.vue"
import NewEditSheet from "@/cost_model/NewEditSheet.vue"

export default {
  name: "CostModelTable",

  components: {
    NewEditSheet,
    DeleteDialog,
    SettingsBreadcrumbs,
  },

  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Description", value: "description", sortable: true },
        { text: "Enabled", value: "enabled", sortable: true },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("cost_model", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
  },

  created() {
    this.project = [{ name: this.$route.query.project }]
    this.getAll()
    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("cost_model", ["createEditShow", "removeShow", "getAll", "createActivityShow"]),
  },
}
</script>
